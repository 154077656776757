import { data } from './constants/data';
import { Question } from './Question';
import { PageTitle, PageWrapper } from './Question.styles';

export const FAQ = () => {
  return (
    <PageWrapper>
      <PageTitle>Frequently asked questions</PageTitle>
      {data.map(({ question, answer }, i) => (
        <Question key={i} answer={answer} question={question} />
      ))}
    </PageWrapper>
  );
};
