import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';

import { ModalDispatchContext } from '../../../../../context/modalContext';
import { CopiesSetData } from '../../../../../pages';
import { addUrlProtocol } from '../../../../../utils/addUrlProtocol';
import { DeviceTypes } from '../../../../../utils/getDeviceType';
import MaxWidth from '../../../../shared/MaxWidth';
import ButtonsBlock from './components/ButtonsBlock';
import FeaturedIn from './components/FeaturedIn';
import * as S from './Hero.styles';

export const Hero = ({ copies }: { deviceType: DeviceTypes; copies: CopiesSetData }) => {
  useOpenVideoModalWithMatchinQueryParam();

  return (
    <S.Wrapper>
      <S.Background />
      <S.Content>
        <MaxWidth>
          <S.InfoBlock>
            {copies.heroTitle && (
              <S.Caption dangerouslySetInnerHTML={{ __html: copies.heroTitle }} />
            )}
            {copies.heroDescription && (
              <S.HeroText dangerouslySetInnerHTML={{ __html: copies.heroDescription }} />
            )}
            <ButtonsBlock />
            <S.FeaturedContainer>
              <FeaturedIn copies={copies} />
            </S.FeaturedContainer>
          </S.InfoBlock>
        </MaxWidth>
      </S.Content>
      <S.DesktopImageBlock>
        {copies.heroDesktopImage?.fields?.file?.url && (
          <Image
            alt={copies.heroDesktopImageAlt || ''}
            height={copies.heroDesktopImageHeight}
            priority
            src={addUrlProtocol(copies.heroDesktopImage.fields.file.url)}
            width={copies.heroDesktopImageWidth}
          />
        )}
      </S.DesktopImageBlock>
      <S.MobileImageBlock>
        {copies.heroMobileAuraImage?.fields?.file?.url && (
          <S.FocusAura
            alt={copies.heroMobileAuraImageAlt || ''}
            height={800}
            priority={true}
            src={addUrlProtocol(copies.heroMobileAuraImage.fields.file.url)}
            width={800}
          />
        )}
        {copies.heroMobileImage?.fields?.file?.url && (
          <Image
            alt={copies.heroMobileImageAlt || ''}
            height={copies.heroMobileImageHeight}
            priority
            src={addUrlProtocol(copies.heroMobileImage.fields.file.url)}
            style={{ zIndex: 1 }}
            width={copies.heroMobileImageWidth}
          />
        )}
      </S.MobileImageBlock>
    </S.Wrapper>
  );
};

function useOpenVideoModalWithMatchinQueryParam() {
  const router = useRouter();
  const setModalState = useContext(ModalDispatchContext);

  useEffect(() => {
    if (router.query.showVideo === 'true') {
      setModalState({ howItWorksVideoVisible: true });
    }
  }, [router.query.showVideo]);
}
