import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import styled from 'styled-components';

import { CopiesSetData } from '../../../pages';
import { NewMusicMondayProps } from '../../../pages/newMusicMonday';
import { DeviceTypes } from '../../../utils/getDeviceType';
import CTABox from '../../_core/CTAbox';
import { PartnerType } from '../../header/PartnerBanner';
import MaxWidth from '../../shared/MaxWidth';
import { FAQ } from './components/FAQ';
import Hero from './components/Hero';
import TestimonialSection from './components/Testimonials';
import TracksList from './components/TracksList';
import VideoPoster from './components/VideoPoster';

const Body = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

const HeroContainer = styled.div<{ minHeight: string }>`
  position: relative;
  min-height: ${props => props.minHeight};
`;

interface Props {
  copiesSet: CopiesSetData;
  deviceType?: DeviceTypes;
  newMusicMonday?: NewMusicMondayProps;
  partner?: PartnerType;
  shouldNotIndex?: boolean;
}

const PartnerHero = dynamic(() => import('./components/PartnerHero'), {
  ssr: true,
});
const ProductDescription = dynamic(() => import('./components/ProductDescription'), {
  ssr: true,
});

export const HomePage: React.FC<Props> = ({
  copiesSet,
  deviceType = DeviceTypes.Desktop,
  newMusicMonday = undefined,
  partner = undefined,
  shouldNotIndex = false,
}) => {
  return (
    <Body>
      <Head>
        {shouldNotIndex ? (
          <meta content="noindex, nofollow" name="robots" />
        ) : null}
      </Head>
      <HeroContainer minHeight={partner ? 'auto' : '100vh'}>
        {!newMusicMonday &&
          (partner ? (
            <MaxWidth>
              <PartnerHero partner={partner} />
            </MaxWidth>
          ) : (
            <Hero copies={copiesSet} deviceType={deviceType} />
          ))}
        {newMusicMonday && (
          <MaxWidth>
            <TracksList data={newMusicMonday} />
          </MaxWidth>
        )}
      </HeroContainer>
      <ProductDescription copies={copiesSet} deviceType={deviceType} />
      <TestimonialSection />
      <div id="faq" />
      <MaxWidth>
        <VideoPoster />
      </MaxWidth>
      <MaxWidth>
        <FAQ />
      </MaxWidth>
      <MaxWidth>{partner ? <PartnerHero partner={partner} /> : <CTABox />}</MaxWidth>
    </Body>
  );
};
