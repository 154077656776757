import styled, { keyframes } from 'styled-components';

import { mediaQuery } from '../../styles/global';
import ReviewData from './data';
import ReviewSingle from './Review';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
`;

const TranslateTop = keyframes`
  0% { 
    transform: translateX(0rem);
   }
  50% {
    transform: translateX(30rem);
  }
  100% { 
    transform: translateX(0rem);
  }
`;
const TranslateBottom = keyframes`
  0% { 
    transform: translateX(20rem);
   }
  50% {
    transform: translateX(0rem);
  }
  100% { 
    transform: translateX(20rem);
  }
`;

const RowTop = styled(Wrapper)`
  transform: translateX(0rem);
  animation: ${TranslateTop};
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;
const RowBottom = styled(Wrapper)`
  transform: translateX(20rem);
  animation: ${TranslateBottom};
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;
const ColumnMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
    display: flex;
  }
`;

const ReviewSlider: React.FC = () => {
  return (
    <>
      <RowTop>
        {ReviewData.slice(0, 4).map(({ tweet, byline, image }, index) => (
          <ReviewSingle key={index} byline={byline} image={image} text={tweet} />
        ))}
      </RowTop>
      <RowBottom>
        {ReviewData.slice(4, 8).map(({ tweet, byline, image }, index) => (
          <ReviewSingle key={index} byline={byline} image={image} text={tweet} />
        ))}
      </RowBottom>
      <ColumnMobile>
        {ReviewData.slice(0, 2).map(({ tweet, byline, image }, index) => (
          <ReviewSingle key={index} byline={byline} image={image} text={tweet} />
        ))}
      </ColumnMobile>
    </>
  );
};

export default ReviewSlider;
