export default [
  {
    image: '/images/brainfm-review-brian.png',
    tweet: 'My NPS score on brain.fm is a 10/10. If you need to crank through some work, check it out.',
    byline: 'Brian',
  },
  {
    image: '/images/brainfm-review-bella.png',
    tweet:
      'Discovered Brain.fm through a podcast, gave it a try, and finished designing an e-commerce website in just a couple of hours that would usually take me days.',
    byline: 'Bella',
  },
  {
    image: '/images/brainfm-review-dave.png',
    tweet:
      'Nothing has positively impacted my ability to focus on work like Brain.fm It provides timed focus music to increase my concentration!',
    byline: 'Dave',
  },
  {
    image: '/images/brainfm-review-joseph.png',
    tweet:
      'Productivity hack: use Brain.fm. I’ve never gotten so much work done at once before using it. It’s my go-to music when I need to bang out emails. ',
    byline: 'Joseph',
  },
  {
    image: '/images/brainfm-review-jan.png',
    tweet:
      'Brain.fm really helps me to stay focussed over longer periods of time when the world around me screams and chaos wants to slow me down. Totally worth a try should you be easily distracted or in a noisy environment.',
    byline: 'Jan',
  },
  {
    image: '/images/brainfm-review-bassem.png',
    tweet:
      "I got a free trial to Brain.fm from work and as I write this tweet, I'm seriously considering canceling my Spotify subscription in favour of this app! It works, it works REALLY well.",
    byline: 'Bassem',
  },
  {
    image: '/images/brainfm-review-courtney.png',
    tweet:
      'Tried it out today! The deep work classical station was perfect. Exactly what I needed. Much easier than curating my own playlists.',
    byline: 'Courtney',
  },
  {
    image: '/images/brainfm-review-tyler.png',
    tweet:
      'Download Brain.fm across all of your devices you use for work and listen to it any time you need to focus. Your output will be tripled.',
    byline: 'Tyler',
  },
];
