import styled from 'styled-components';

import { mediaQuery } from '../../../../../styles/global';
import { H2 } from '../../../../_core/Typography';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem 3rem 12rem 3rem;
`;

export const PageTitle = styled(H2)`
  margin-bottom: 3rem;
  /* text-align: center; */
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:last-child {
    border-bottom: none;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  padding: 0.75rem 1rem 0.75rem 0rem;

  ${mediaQuery.minWidth.tablet} {
    font-size: 1.375rem;
  }
`;

export const Description = styled.div<{ isOpen: boolean }>`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  height: auto;
  line-height: 1.6em;
  margin-left: 2.5rem;
  max-height: ${({ isOpen }) => (isOpen ? '50rem' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  font-size: 1.125rem;
  transition:
    max-height 0.25s ease-in-out,
    opacity 0.2s ease-in-out;
`;
