import Link from 'next/link';

export const data: { question: string; answer: JSX.Element }[] = [
  {
    question: 'Why does brain.fm increase focus?',
    answer: (
      <div>
        <p>
          Your brainwaves operate at different speeds (Hz) that scientists generally break down into
          4 categories that are associated with different mental states.
        </p>
        <ol style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
          <li>Delta (0.5-4 Hz) - Deep sleep</li>
          <li>Theta (4-8 Hz) - Light sleep and deep relaxation </li>
          <li>Alpha (8-12 Hz) - Relaxed wakefulness, creativity, and meditation </li>
          <li>Beta (12-30 Hz) - Active thinking, problem-solving, and focus</li>
        </ol>
        <p>
          {`Brain.fm identifies the optimal Hz range for a given mental state and translates it into
          volume modulations within certain areas of our music. This encourages your brain to
          increase the power level of brainwaves that are equal to or similar to the patterns we've
          embedded in the music.`}
        </p>
        <p>
          This process not only enhances brainwave activity but also synchronizes your brainwaves
          throughout your brain, making it easier for different parts of your brain to work together
          more seamlessly.
        </p>
        <p>
          {`It may sound wild, but we've conducted quality research, published multiple papers, and
          received a grant from the National Science Foundation to continue our work.`}
        </p>
        <p>
          In short—brain.fm is a safe, evidence-based way to hack your brain and take control of
          your mental state on demand using sound.
        </p>
      </div>
    ),
  },
  {
    question: 'Is this the same as binaural beats?',
    answer: (
      <div>
        <p>
          {`Brain.fm is not binaural beats. There are some similarities, but it’s important to
          remember that binaural beats were first discovered in 1839, popularized in the 1970’s and
          haven’t really changed since.`}
        </p>
        <p>
          In a way, you could think of brain.fm as binaural beats 2.0. We took the concept behind
          binaural beats and applied an updated understanding of neuroscience and auditory
          processing to create a more effective and powerful solution.
        </p>
        <p>Some core differences:</p>
        <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
          <li>
            Binaural beats primarily impacts the lower brain which is not where thinking or
            creativity occurs.
          </li>
          <li>
            Brain.fm impacts the prefrontal cortex which is where all your thinking and creativity
            comes from
          </li>
          <li>
            Binaural beats requires both ears to be free of hearing damage and high quality
            headphones to be on.
          </li>
          <li>
            Brain.fm works with one or two ears, does not require headphones, and is not dependent
            on your ability to hear certain frequencies.
          </li>
        </ul>
        <p>
          Most importantly, brain.fm uses many methods to make our music work, unlike binaural beats
          which is just one technique!
        </p>
      </div>
    ),
  },
  {
    question: 'How large are your scientific studies?',
    answer: (
      <div>
        <p>
          {`We’ve conducted research and testing with hundreds of participants. We always include
          placebo groups to make sure it’s not just our music that increases focus, but the
          technology embedded within it.`}
        </p>
        <p>
          You can read our papers, supporting research, and more at{' '}
          <Link href="/science" style={{ color: 'white' }}>
            {' '}
            www.brain.fm/science
          </Link>
        </p>
      </div>
    ),
  },
  {
    question: 'Is this only for people with ADHD?',
    answer: (
      <div>
        <p>
          {`Brain.fm is highly effective for people with ADHD or attention difficulties, but it’s also
          helpful for people who don’t fall into that category.`}
        </p>
        <p>This is why we have multiple neural effect levels inside our app.</p>
        <p>
          {`Some brains need only a little help to get going and stay focused, while others require
          more. We’ve found that adjusting these levels based off a few simple questions in our app
          gets most people to their “happy place” when it comes to focus.`}
        </p>
        <p>
          {`But if we’re honest with ourselves—who among us doesn’t have trouble focusing these days?`}
        </p>
      </div>
    ),
  },
  {
    question: 'Why is this better for focus than normal music?',
    answer: (
      <div>
        <p>
          {`Our studies show that normal music can help increase your focus for a short period of
          time, but then mental performance starts to decline. Brain.fm maintains your focus because
          the music consistently encourages your brainwaves to stay in a pattern associated with
          focus. Additionally, with the way brain.fm works, you don't spend as much time skipping
          songs you don’t like, or looking for the next song that feels good.`}
        </p>
      </div>
    ),
  },
  {
    question: 'How do I make brain.fm work best for me?',
    answer: (
      <div>
        <p>
          {`There are a number of factors that go into determining your specific neurotype and what
          settings will be best for you in brain.fm. When you sign up, there’s a short quiz that
          helps us adjust the music so that your brain won’t feel bored or over-stimulated by the
          special patterns inside our music.`}
        </p>
      </div>
    ),
  },
  {
    question: 'Where do you get your music?',
    answer: (
      <div>
        <p>
          We employ multiple full-time composers that create original music informed by our
          research. This gives us a lot more control over how we deliver the custom patterns to your
          brain through the music in the most pleasing way.
        </p>
      </div>
    ),
  },
  {
    question: 'Do you have special pricing for teams?',
    answer: (
      <div>
        <p>
          Yes! We support team accounts for companies, classrooms, and more!&nbsp; 
          <a href="https://form.typeform.com/to/aJVwOSqR" rel="noreferrer" style={{ color: 'white'}} target="_blank">Contact us here</a>
          &nbsp;to learn about special pricing for teams.
        </p>
      </div>
    ),
  },
  {
    question: 'Do you have special pricing for students?',
    answer: (
      <div>
        <p>
          Yes! We offer a 20% discount for active students. To get a coupon code, just email&nbsp; 
          <a href="mailto:support@brain.fm" rel="noreferrer" style={{ color: 'white'}}  target="_blank">support@brain.fm</a> from a ‘.edu’ email address, 
          OR with a copy of your student I.D. or equivalent confirmation of school enrollment.
        </p>
      </div>
    ),
  },
];
