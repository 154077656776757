import React, { useCallback, useContext } from 'react';

import { ModalDispatchContext } from '../../../../../context/modalContext';
import { Assets } from '../../../../../utils/assets';
import { H2 } from '../../../../_core/Typography';
import * as S from './VideoPoster.styles';

export const VideoPoster = () => {
  const setModalState = useContext(ModalDispatchContext);
  const onPlay = useCallback(() => {
    setModalState({ howItWorksVideoVisible: true });
  }, [setModalState]);

  return (
    <S.Wrapper>
      <H2>Learn more about how Brain.fm works</H2>
      <S.PosterBlock>
        <video autoPlay loop muted style={{ width: '100%', height: 'auto', borderRadius: '28px' }}>
          <source src={Assets.videos.howItWorks.url} />
        </video>
        <S.ButtonWrapper>
          <S.Button onClick={onPlay}>
            <img alt="play" src="https://cdn.brain.fm/icons/play.svg" width="18px" />
            PLAY VIDEO <S.Tag>0:58</S.Tag>
          </S.Button>
        </S.ButtonWrapper>
      </S.PosterBlock>
    </S.Wrapper>
  );
};
