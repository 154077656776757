import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from '../../styles/global';
import { Assets } from '../../utils/assets';

const Container = styled.div`
  padding-left: 5rem;
  ${mediaQuery.maxWidth.tablet} {
    padding-left: 3rem;
  }
`;

const ReviewPic = styled.img`
  position: absolute;
  left: -3rem;
  top: 3.125rem;
  width: 6rem;
  height: 6rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  ${mediaQuery.maxWidth.tablet} {
    top: 2.5rem;
  }
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  padding-left: 7rem;
  height: 14rem;
  width: 35rem;
  background: url(${Assets.images.quoteBg.url}) no-repeat;
  background-size: contain;
  box-shadow: 0px 20px 80px rgba(25, 23, 54, 0.65);

  ${mediaQuery.maxWidth.desktop} {
    width: 25rem;
    height: 10rem;
    // padding: 1rem;
    padding-left: 5rem;
    font-size: 0.825rem;
  }
  ${mediaQuery.maxWidth.tablet} {
    margin-bottom: 2rem;
  }
`;

const Text = styled.div`
  padding: 1rem 0;
`;
const Rating = styled.div``;
const Byline = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.6rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
`;

interface ReviewProps {
  text: string;
  byline: string;
  image: string;
}

const ReviewSingle: React.FC<ReviewProps> = ({ text, byline, image }) => {
  return (
    <Container>
      <Wrapper>
        <ReviewPic src={image} />
        <Rating>
          <StarImage />
          <StarImage />
          <StarImage />
          <StarImage />
          <StarImage />
        </Rating>
        <Text>{text}</Text>
        <Byline>{byline}</Byline>
      </Wrapper>
    </Container>
  );
};

export default ReviewSingle;

const StarWrapper = styled.img`
  margin-right: 0.375rem;
`;
function StarImage() {
  return <StarWrapper alt="star" src="/images/star.svg" />;
}
