import React, { useContext } from 'react';
import styled from 'styled-components';

import { ModalDispatchContext } from '../../context/modalContext';
import { mediaQuery } from '../../styles/global';
import { Assets } from '../../utils/assets';
import { H2 } from '../_core/Typography';
import { PrimaryButtonLink } from '../button';

const Container = styled.div`
  display: flex;
  background: radial-gradient(90.66% 140.8% at 73.3% -5.93%, #8806be 0%, #110e4e 100%);
  box-shadow: 0px 18.484px 73.9361px rgba(25, 23, 54, 0.65);
  border-radius: 34.683px;
  padding: 5rem;
  margin-bottom: 2rem;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-right: 2rem;
  ${mediaQuery.maxWidth.tablet} {
    width: 100%;
    padding-right: 0rem;
  }
`;

const ButtonWrapper = styled.div``;

const RightImage = styled.img`
  width: 50%;
  height: auto;

  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;

const Text = styled.p`
  margin-bottom: 3rem;
`;

const FeaturedIn: React.FC = () => {
  const setModalState = useContext(ModalDispatchContext);

  return (
    <Container>
      <Left>
        <H2>
          Get started with <br />
          brain.fm today.
        </H2>
        <Text>
          Our science-first approach creates music that sounds different and affects your brain
          differently than any other music.
        </Text>
        <ButtonWrapper>
          <PrimaryButtonLink
            analyticsEvent={{ category: 'Purple CTA', action: 'Signup Button Click' }}
            isAnchortag={false}
            onClick={() => setModalState({ signupVisible: true })}
          >
            Start free trial
          </PrimaryButtonLink>
        </ButtonWrapper>
      </Left>
      <RightImage
        alt={Assets.images.ctaLandingPreview.alt}
        src={Assets.images.ctaLandingPreview.url}
      />
    </Container>
  );
};

export default FeaturedIn;
