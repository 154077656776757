import styled from 'styled-components';

import { H2 } from '../../../_core/Typography';
import ReviewSlider from '../../../reviews';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const Header2 = styled(H2)`
  text-align: center;
`;

const TestimonialWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  overflow-x: hidden;
`;

export const TestimonialSection: React.FC = () => {
  return (
    <>
      <Container>
        <Header2>
          Join millions focusing
          <br /> on demand with brain.fm
        </Header2>
        <TestimonialWrapper>
          <ReviewSlider />
        </TestimonialWrapper>
      </Container>
    </>
  );
};

export default TestimonialSection;
