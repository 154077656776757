import styled from 'styled-components';

import { mediaQuery } from '../../../../../styles/global';

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 500px;
  width: 100%;
  position: relative;
  ${mediaQuery.maxWidth.desktop} {
    flex-direction: column-reverse;
    height: auto;
    padding: 0 20px;
  }
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4rem;
  gap: 0.4rem;
  box-shadow: 0px 2.542509078979492px 11.441289901733398px 0px rgba(147, 35, 102, 0.4);
  padding: 0.8rem 2rem;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: scale 0.3s ease;
  text-align: center;
  font-size: 0.85rem;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  ${mediaQuery.maxWidth.tablet} {
    font-size: 0.65rem;
  }
  &:hover {
    scale: 1.1;
  }
`;
export const Tag = styled.span`
  background: #0d0c11bf;
  border-radius: 4px;
  color: white;
  font-size: 0.75rem;
  padding: 2px 4px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const PosterBlock = styled.div`
  position: relative;
`;
